import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import homeScreenImage from '../images/home-screen.png';
import pillImage from '../images/pill.png';
import Footer from '../components/Footer';
import { FaRegCircle, FaThumbsUp, FaUser, FaShoppingCart, FaChartLine, FaLock, FaHandHoldingMedical, FaSearch } from 'react-icons/fa';
import './../styles/steps.css';
import appStoreImage from '../images/appstore.png'; // Replace with your actual image path
import googlePlayImage from '../images/playstore.png';

const HomeContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  color: #333;
  text-align: center;
  overflow-x: hidden;
`;



const Banner = styled.div`
  width: 100%;
  height: 50px;
  background-color: #3f60ac;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 70px;
    margin-bottom: 30px;
  }
`;

const BannerText = styled.p`
  font-size: 0.9rem;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;

  @media (min-width: 768px) {
    font-size: 1.1rem;
  }

  a {
    color: white;
    text-decoration: underline;
    font-weight: bold;
    margin-left: 5px; // Add space between the text and the link if needed

    &:hover {
      color: #51b848; // Change color on hover for better visibility
    }
  }
`;

const Heading = styled(motion.h1)`
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Cinzel Decorative', sans-serif;
  margin-bottom: 1rem;
  text-align: center;
  line-height: 1.2;
  width: 90%;

  @media (min-width: 768px) {
    font-size: 3rem;
    width: 500px;
  }

  @media (max-width: 468px) {
    font-size: 2rem;
    width: 90%;
  }
`;

const Subheading = styled(motion.p)`
  font-size: 1.2rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const MainImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CircleBackground = styled.div`
  position: absolute;
  width: 100%;
  max-width: 350px;
  height: 350px;
  background: #3f60ac;
  border-radius: 50%;
  z-index: -1;

  @media (min-width: 768px) {
    max-width: 600px;
    height: 600px;
  }
`;

const MainImage = styled(motion.img)`
  width: 80%;
  max-width: 300px;
  margin: 2rem 0;

  @media (min-width: 768px) {
    width: 60%;
    max-width: 600px;
  }
`;

const PillImage = styled(motion.img)`
  position: absolute;
  width: 50px;
  height: 50px;

  @media (min-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const Section = styled.section`
  margin: 40px 0;
  text-align: center;
`;

const SectionTitle = styled(motion.h2)`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const Feature = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  max-width: 200px;
  text-align: center;
`;

const FeatureIcon = styled.div`
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #3f60ac;
`;

const FeatureDescription = styled.p`
  font-size: 1rem;

  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`;

const StepList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const Step = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  max-width: 200px;
  text-align: center;
`;

const StepIcon = styled.div`
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #51b848;
  position: relative;
`;

const StepNumber = styled.span`
  position: absolute;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
`;

const StepDescription = styled.p`
  font-size: 1rem;

  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`;

const TestimonialList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const Testimonial = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  max-width: 300px;
  text-align: center;
`;

const TestimonialText = styled.p`
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    font-size: 1.1rem;
  }
`;

const TestimonialAuthor = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const CallToActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 40px 0;
`;

const CallToActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const CallToActionImage = styled(motion.img)`
  width: 200px;
  margin: 10px;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 250px;
  }
`;


// Animation Variants
const pillAnimation = {
  initial: {
    y: 0,
    opacity: 0,
  },
  animate: {
    y: [0, 800],
    opacity: [1, 0],
    transition: {
      duration: 4,
      repeat: Infinity,
      repeatType: 'loop',
    },
  },
};

const fadeInUp = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      ease: 'easeOut',
    },
  },
};

const Home = () => {
  return (
    <HomeContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
     <Banner>
    <BannerText>
      Are you a pharmacy? <a href="/contact">Join us now</a>
    </BannerText>
  </Banner>
      <Heading initial="initial" animate="animate" variants={fadeInUp}>
        Local Pharmacies at Your Fingertips!
      </Heading>
      <Subheading initial="initial" animate="animate" variants={fadeInUp}>
        Convenient, Anonymous, and Fast Medicine Search and Delivery.
      </Subheading>
      <MainImageContainer>
        <CircleBackground />
        <MainImage
          src={homeScreenImage}
          alt="PharmaApp Home Screen"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        />
      </MainImageContainer>
      <Section>
        <SectionTitle initial="initial" animate="animate" variants={fadeInUp}>
          Features
        </SectionTitle>
        <FeatureList>
          <Feature
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <FeatureIcon><FaSearch /></FeatureIcon>
            <FeatureDescription>Quickly search for medicines available at nearby pharmacies.</FeatureDescription>
          </Feature>
          <Feature
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <FeatureIcon><FaHandHoldingMedical /></FeatureIcon>
            <FeatureDescription>Get real-time location updates and find the nearest pharmacies with available stock.</FeatureDescription>
          </Feature>
          <Feature
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <FeatureIcon><FaLock /></FeatureIcon>
            <FeatureDescription>Place your orders discreetly and pick them up with complete privacy.</FeatureDescription>
          </Feature>
          <Feature
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.8 }}
          >
            <FeatureIcon><FaShoppingCart /></FeatureIcon>
            <FeatureDescription>Enjoy a smooth and hassle-free pickup process at your chosen pharmacy.</FeatureDescription>
          </Feature>
        </FeatureList>
      </Section>
      <Section>
        <SectionTitle initial="initial" animate="animate" variants={fadeInUp}>
          How It Works
        </SectionTitle>
        <StepList>
          <Step
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <StepIcon>
              <FaRegCircle className="number-icon" />
              <StepNumber>1</StepNumber>
            </StepIcon>
            <StepDescription>Use our app to search for the medicines you need from nearby pharmacies.</StepDescription>
          </Step>
          <Step
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <StepIcon>
              <FaRegCircle className="number-icon" />
              <StepNumber>2</StepNumber>
            </StepIcon>
            <StepDescription>Select the pharmacy and place your order discreetly through the app.</StepDescription>
          </Step>
          <Step
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <StepIcon>
              <FaRegCircle className="number-icon" />
              <StepNumber>3</StepNumber>
            </StepIcon>
            <StepDescription>Collect your medicines from the pharmacy or request for delivery without any hassle or privacy concerns.</StepDescription>
          </Step>
        </StepList>
      </Section>
      <Section>
        <SectionTitle initial="initial" animate="animate" variants={fadeInUp}>
          What Our Users Say
        </SectionTitle>
        <TestimonialList>
          <Testimonial
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <TestimonialText>"Edidro made it so easy to find my medication and pick it up without any hassle!"</TestimonialText>
            <TestimonialAuthor><FaUser /> - Sarah L.</TestimonialAuthor>
          </Testimonial>
          <Testimonial
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <TestimonialText>"The app is a lifesaver! I love how private and convenient the whole process is."</TestimonialText>
            <TestimonialAuthor><FaUser /> - Michael T.</TestimonialAuthor>
          </Testimonial>
          <Testimonial
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <TestimonialText>"As a pharmacy owner, Edidro's dashboard has streamlined our operations and improved efficiency."</TestimonialText>
            <TestimonialAuthor><FaUser /> - Dr. Emma K.</TestimonialAuthor>
          </Testimonial>
        </TestimonialList>
      </Section>
      <CallToActionContainer>
        <SectionTitle initial="initial" animate="animate" variants={fadeInUp}>
          Join Thousands of Satisfied Users
        </SectionTitle>
        <Subheading initial="initial" animate="animate" variants={fadeInUp}>
          Download Edidro Today and Experience a New Way to Manage Your Medicines
        </Subheading>
        <CallToActionButtons>
          <motion.a
            href="https://apps.apple.com"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <CallToActionImage
              src={appStoreImage}
              alt="Download on the App Store"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            />
          </motion.a>
          <motion.a
            href="https://play.google.com/store/apps/details?id=com.bentak10.edidro_app"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.8 }}
          >
            <CallToActionImage
              src={googlePlayImage}
              alt="Get it on Google Play"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            />
          </motion.a>
        </CallToActionButtons>
      </CallToActionContainer>
      <Footer />
    </HomeContainer>
  );
};

export default Home;
