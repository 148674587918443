import React from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';
import {motion} from 'framer-motion';
 
const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  color: #333;

  @media (min-width: 768px) {
    padding: 40px;
  }
`;
const HomeContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  color: #333;
  text-align: center;

  @media (min-width: 768px) {

  }
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Cinzel Decorative', sans-serif;
  margin-bottom: 1rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const Section = styled.section`
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const List = styled.ul`
  padding-left: 20px;
  list-style-type: disc;

  @media (min-width: 768px) {
    padding-left: 40px;
  }
`;

const ListItem = styled.li`
  margin-bottom: 1rem;
  line-height: 1.6;
`;

const WhyUs = () => {
  return (
    <HomeContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
    <Container>
      <Title>Why Us</Title>
      <Section>
        <SectionTitle>Why Choose Edidro?</SectionTitle>
        <List>
          <ListItem><strong>Convenience:</strong> Find and order medicines from the comfort of your home or on the go. Our app provides a seamless experience for locating nearby pharmacies and placing orders quickly.</ListItem>
          <ListItem><strong>Privacy:</strong> We prioritize your privacy. Orders are handled discreetly, ensuring that your personal information and medication details remain confidential.</ListItem>
          <ListItem><strong>Efficiency:</strong> Our comprehensive dashboard helps pharmacies manage their inventory, transactions, and staff efficiently, ensuring they always have the right stock at the right time.</ListItem>
          <ListItem><strong>Accessibility:</strong> Whether you need medicines locally or internationally, Edidro's expansive network helps you find what you need, wherever you are.</ListItem>
          <ListItem><strong>User-Friendly:</strong> Our platform is designed with you in mind. Both the app and the pharmacy dashboard are easy to navigate, making your experience smooth and hassle-free.</ListItem>
        </List>
        <Paragraph>Choose Edidro for a modern, reliable, and private solution to your pharmaceutical needs. We are committed to making healthcare more accessible and efficient for everyone.</Paragraph>
      </Section>
    </Container>
    <Footer/>
    </HomeContainer>
  );
}

const Paragraph = styled.p`
  margin-top: 1rem;
  line-height: 1.6;
  text-align: center;
`;

export default WhyUs;
