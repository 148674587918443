import React from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';
import {motion} from 'framer-motion';

const Container = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  color: #333;

  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const HomeContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  color: #333;
  text-align: center;

  @media (min-width: 768px) {

  }
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Cinzel Decorative', sans-serif;
  margin-bottom: 1rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const Section = styled.section`
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const Paragraph = styled.p`
  margin-bottom: 1rem;
  line-height: 1.6;
`;

const List = styled.ul`
  margin-left: 20px;
  margin-bottom: 1rem;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
  line-height: 1.6;
`;

const Privacy = () => {
  return (
    <HomeContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
    <Container>
      <Title>Privacy Policy</Title>
      <Section>
        <SectionTitle>Introduction</SectionTitle>
        <Paragraph>
          Effective Date: 2nd July 2024
        </Paragraph>
        <Paragraph>
          Edidro ("we", "our", "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you use our platform, which includes our website, mobile applications, and services (collectively, the "Services").
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>Information We Collect</SectionTitle>
        <List>
          <ListItem><strong>Personal Information:</strong> When you register or use our Services, we may collect personal information such as your name, email address, phone number, and payment information.</ListItem>
          <ListItem><strong>Usage Data:</strong> We collect information about how you interact with our Services, including your search queries, pages viewed, and other actions.</ListItem>
          <ListItem><strong>Location Data:</strong> With your consent, we may collect and use information about your location to provide location-based services.</ListItem>
        </List>
      </Section>
      <Section>
        <SectionTitle>How We Use Your Information</SectionTitle>
        <List>
          <ListItem><strong>To Provide and Improve Services:</strong> We use your information to deliver and improve our Services, process transactions, and provide customer support.</ListItem>
          <ListItem><strong>To Communicate with You:</strong> We may use your information to send you updates, promotional materials, and other information related to our Services.</ListItem>
          <ListItem><strong>To Ensure Privacy:</strong> Your order details and medicine information are handled discreetly to ensure your privacy.</ListItem>
        </List>
      </Section>
      <Section>
        <SectionTitle>Sharing Your Information</SectionTitle>
        <List>
          <ListItem><strong>Third-Party Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing and data analysis.</ListItem>
          <ListItem><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</ListItem>
        </List>
      </Section>
      <Section>
        <SectionTitle>Data Security</SectionTitle>
        <Paragraph>
          We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or method of electronic storage is 100% secure.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>Your Rights</SectionTitle>
        <Paragraph>
          You have the right to access, correct, or delete your personal information. To exercise these rights, please contact us at [contact email].
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>Changes to This Policy</SectionTitle>
        <Paragraph>
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>Contact Us</SectionTitle>
        <Paragraph>
          If you have any questions about this Privacy Policy, please contact us at info@edidro.com.
        </Paragraph>
      </Section>
    </Container>
    <Footer/>
    </HomeContainer>
  );
}

export default Privacy;
