import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Footer from '../components/Footer';

const SupportContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  background: white;
  color: #333;
  text-align: center;
  padding: 0 20px;
`;
const HomeContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  color: #333;
  text-align: center;

  @media (min-width: 768px) {

  }
`;

const Heading = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Description = styled.p`
  font-size: 1.25rem;
  max-width: 600px;
`;

const Button = styled(motion.a)`
  padding: 10px 20px;
  background-color: #3f60ac;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    background-color: #51b848;
  }
`;

function Support() {
  return (
    <HomeContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
    <SupportContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <Heading>Support</Heading>
      <Description>If you need help or have any questions, our support team is here to assist you. Contact us for any assistance you require.</Description>
      <Button href="/contact" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
        Get Support
      </Button>
    </SupportContainer>
    <Footer/>
    </HomeContainer>
  );
}

export default Support;
