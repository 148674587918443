import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background: white;
    color: #333;
  }
  
  a {
    text-decoration: none;
    color: #3f60ac;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #3f60ac;
  }
`;

export default GlobalStyle;
