import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #3f60ac;
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
`;

const QuickLinks = styled.div`
  margin-bottom: 10px;

  a {
    color: white;
    text-decoration: none;
    margin: 0 10px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const SocialMediaIcons = styled.div`
  margin-bottom: 10px;

  a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    font-size: 1.5rem;

    &:hover {
      color: #51b848;
    }
  }
`;

const ContactInformation = styled.div`
  margin-bottom: 10px;

  p {
    margin: 5px 0;
  }
`;

const Disclaimer = styled.div`
  font-size: 0.8rem;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <QuickLinks>
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/why-us">Why Us</a>
        <a href="/contact">Contact</a>
        <a href="/privacy">Privacy</a>
        <a href="/support">Support</a>
        <a href="/accountdel">Account Deletion</a>
      </QuickLinks>
      <SocialMediaIcons>
        <a href="#"><i className="fab fa-facebook"></i></a>
        <a href="#"><i className="fab fa-twitter"></i></a>
        <a href="#"><i className="fab fa-instagram"></i></a>
        <a href="#"><i className="fab fa-linkedin"></i></a>
      </SocialMediaIcons>
      <ContactInformation>
        <p>Email: info@edidro.com</p>
        <p>Phone: +123 456 7890</p>
        <p>Address: 1234 Edidro St, Accra, Ghana</p>
      </ContactInformation>
      <Disclaimer>
        © 2024 Edidro. All Rights Reserved.
      </Disclaimer>
    </FooterContainer>
  );
};

export default Footer;
