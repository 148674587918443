import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../images/edidro-logo.png'; // Replace with your actual logo path
import { FaBars } from 'react-icons/fa'; // Importing the hamburger menu icon

const Nav = styled.nav`
  background: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
`;

const Logo = styled.img`
  width: 100px; /* Adjust size as needed */
  height: auto;
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center; /* Center the NavList horizontally */
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    position: absolute;
    top: 100%; /* Position below the Nav */
    left: 0;
    background: #fff;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: ${props => (props.open ? 'flex' : 'none')};
    z-index: 10;
  }
`;

const NavItem = styled.li`
  margin: 0.5rem 1rem;

  @media (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

const NavLink = styled(Link)`
  color: #3f60ac;
  font-size: 1rem;
  font-weight: 600;  // Thicker font
  font-family: 'Roboto', sans-serif;  // Custom font family
  text-decoration: none;
  &:hover {
    color: #51b848;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const MenuIcon = styled.div`
  display: none; /* Hide by default for larger screens */
  cursor: pointer;

  @media (max-width: 768px) {
    display: block; /* Show only on smaller screens */
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
`;

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Nav>
      <Logo src={logo} alt="Logo" />
      <MenuIcon onClick={toggleMenu}>
        <FaBars size={24} />
      </MenuIcon>
      <NavList open={menuOpen}>
        <NavItem><NavLink to="/" onClick={() => setMenuOpen(false)}>Home</NavLink></NavItem>
        <NavItem><NavLink to="/about" onClick={() => setMenuOpen(false)}>About</NavLink></NavItem>
        <NavItem><NavLink to="/why-us" onClick={() => setMenuOpen(false)}>Why Us</NavLink></NavItem>
        <NavItem><NavLink to="/contact" onClick={() => setMenuOpen(false)}>Contact</NavLink></NavItem>
        <NavItem><NavLink to="/privacy" onClick={() => setMenuOpen(false)}>Privacy</NavLink></NavItem>
        <NavItem><NavLink to="/support" onClick={() => setMenuOpen(false)}>Support</NavLink></NavItem>
      </NavList>
    </Nav>
  );
}

export default Header;
