import React from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import Footer from '../components/Footer';

const DataDeletionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  color: #333;
  padding: 20px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const HomeContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  color: #333;
  text-align: center;

  @media (min-width: 768px) {

  }
`;

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const Subheading = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const InfoText = styled.p`
  font-size: 1rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  resize: vertical;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #3f60ac;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: #51b848;
  }
`;

const DataDeletion = () => {
  return (
    <HomeContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
    <DataDeletionContainer>
      <Heading>Account Deletion Request</Heading>
      <Subheading>We Value Your Privacy</Subheading>
      <InfoText>
        If you would like to delete your account and personal data from our system, please fill out the form below. We will process your request and ensure your data is removed.
      </InfoText>
      <Form>
        <Input type="text" placeholder="Full Name" required />
        <Input type="email" placeholder="Phone number" required />
        <TextArea placeholder="Reason for Data Deletion Request" rows="4" required />
        <Button type="submit">Submit Request</Button>
      </Form>
    </DataDeletionContainer>
    <Footer />
    </HomeContainer>
  );
};

export default DataDeletion;
