import React from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';

const Container = styled(motion.div)`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: white;
  color: #333;

  @media (min-width: 768px) {
    padding: 40px;
  }
`;

const HomeContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  color: #333;
  text-align: center;

  @media (min-width: 768px) {

  }
`;


const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  font-family: 'Cinzel Decorative', sans-serif;
  margin-bottom: 1rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const Section = styled.section`
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const Paragraph = styled.p`
  margin-bottom: 1rem;
  line-height: 1.6;
`;

const About = () => {
  return (
    <HomeContainer initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
    <Container>
      <Title>About Us</Title>
      <Section>
        <SectionTitle>About Edidro</SectionTitle>
        <Paragraph>
          Edidro is a cutting-edge platform dedicated to transforming the pharmaceutical industry by making it easier for users to find and order medicines. Founded with the vision to bridge the gap between pharmacies and customers, Edidro offers an intuitive solution for both parties.
        </Paragraph>
        <Paragraph>
          Our platform consists of a user-friendly mobile app for customers and a comprehensive dashboard for pharmacies. The app allows users to search for medicines, place orders, and pick up their medications discreetly. For pharmacies, the dashboard streamlines inventory management, POS transactions, and employee oversight.
        </Paragraph>
        <Paragraph>
          At Edidro, we believe in the importance of timely and private access to healthcare. Our mission is to enhance the efficiency and accessibility of pharmaceutical services across Africa and beyond.
        </Paragraph>
      </Section>
    </Container>
    <Footer/>
    </HomeContainer>
  );
}

export default About;
